import React from 'react'
import { config, useSpring } from 'react-spring'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { Link } from 'gatsby'
import Layout, { MyPBox } from '../components/layout'
import { AnimatedBox, Button } from '../elements'
import SEO from '../components/SEO'
import * as cfg from '../../config'
import IconSmile from '../icon_smile2.svg'
import IconPaint from '../icon_paint.svg'
import IconCrane from '../icon_crane.svg'

const Content = styled(AnimatedBox)<{ bg: string }>`
  background-color: ${props => transparentize(0.9, props.bg)};

  .photoCaption {
    font-size: 0.8rem;
    text-align: center;
    font-style: italic;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.1rem;
    margin-top: 2rem;
  }
`

const PButtonEmpty = styled(Button)<{ color: string }>`
  background-color: transparent;
`

const SvgIcon = styled.img<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
`

const Legal = () => {
  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const contentAfterAnimation = useSpring({ config: config.slow, delay: 800, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout>
      <SEO title={`Error${cfg.siteTitleSuffix}`} desc="Error" />
      <AnimatedBox style={titleAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8]}>
        <h1>Page Not Found</h1>
      </AnimatedBox>
      <Content style={contentAnimation} bg={cfg.backgroundColor} py={8}>
        <MyPBox pb={[1]}>
          <p>Well, this page does not exist.</p>
          <p>
            If you think is is an error, please <Link to="/about">let me know</Link>. Thanks a lot :)
          </p>
        </MyPBox>
      </Content>
    </Layout>
  )
}

export default Legal
